import Vue from 'vue'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import i18n from '@/i18n'
import BreadcrumbComponent from '@/components/commons/breadcrumb/BreadcrumbComponent.vue'
import SelectorComponent from '@/components/commons/selector/SelectorComponent.vue'
import thingApi from '@/api/thing.api'
import reportStorageApi from '@/api/report-storage.api'
import harvestFrontLocationApi from '@/api/harvestFrontLocation.api'
import { getChildrenFromList, dateSortFunction } from '@/tools/functions'
import { filterRulesMapped } from '@/tools/filterRules'
import { geoReferenceApi, cancelGeoData, cancelPOI } from '@/api/geoReference.api'
import AddPOIDialogComponent from '@/components/commons/point-of-interest/AddPOIDialog.vue'
import { TimeRanges, CustomTimeRangeTypes } from '@colven/common-domain-lib/lib'
import service from '@/middleware'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import harvestFrontLocationReportService from '../../business/harvestFrontLocationReportService'

export default {
  name: 'HarvestFrontLocationReportComponent',
  props: {
  },
  components: {
    BreadcrumbComponent,
    SelectorComponent,
    AddPOIDialogComponent,
    AutocompleteComponent
  },
  data () {
    return {
      tabs: [
        {
          id: 'tab-map',
          name: i18n.t('map.map')
        },
        {
          id: 'tab-table',
          name: i18n.t('harvestFrontLocationReport.title')
        }
      ],
      selectedTab: 'tab-table',
      breadcrumbButtomsHarvestFrontLocation: [],
      showSelector: false,
      selectorDisabled: false,
      selectorModel: {
        showClose: true,
        filters: [
          {
            id: 'filter-1',
            name: i18n.t('headers.harvestFront'),
            show: false,
            disabled: false,
            singleSelect: false,
            showDialog: false,
            data: [],
            selectedData: [],
            selectAction: undefined
          }
        ],
        selects: [],
        disableGetReportButton: true,
        showDateAndTimeSelector: false,
        selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
        selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
        sinceDate: null,
        sinceTime: null,
        toDate: null,
        toTime: null,
        customDateTimeValidForm: false,
        autoReport: false,
        autoReportMinutes: 10
      },
      intervalId: null,
      things: [],
      thingsId: [],
      currentReportKey: undefined,
      mapConfiguration: [],
      markers: [],
      tableHeaders: [],
      tableFilterRules: {},
      tableData: [],
      tableDataKey: 'trackId',
      tableComponentButtons: [],
      disableRefreshButton: true,
      geoDataIndex: -1,
      poiDataIndex: -1,
      maxRadius: 20,
      requestCountGeoData: 0,
      requestCountPOI: 0,
      rowActions: [
        {
          id: 1,
          name: i18n.t('poi.create'),
          color: 'blue',
          icon: 'push_pin'
        }
      ],
      addPOILat: 0,
      addPOILong: 0,
      addPOIEnterpriseId: null,
      showAddPOI: false,
      // se activa cuando las referencias fueron resueltas
      referenceResolved: false,
      // se activa cuando las ubicaciones fueron resueltas
      ubicationResolved: false,
      middleware: service.http
    }
  },
  computed: {
    ...mapGetters('breadcrumb', {
      getDetails: 'getDetails'
    })
  },
  created () {
    // instancia del timeout para completar los datos de las direcciones
    this.$_debounceTimeoutId = null
    this.breadcrumbButtomsHarvestFrontLocation.push(
      {
        id: 'setting-btn',
        icon: 'settings',
        show: this.showSelectorButton.bind(this),
        disable: this.disableSelectorButton.bind(this),
        function: this.selector.bind(this)
      })
    this.tableComponentButtons.push(
      {
        id: 'get-geo-data',
        tooltip: i18n.t('poi.loadLocations'),
        icon: 'explore',
        action: this.fireGeoDataRequests.bind(this),
        selectedData: false
      })
    this.tableComponentButtons.push(
      {
        id: 'get-POI',
        tooltip: i18n.t('poi.load'),
        icon: 'push_pin',
        action: this.firePOIRequests.bind(this),
        selectedData: false
      })
  },
  beforeDestroy () {
    this.closeReportDefer()
    clearInterval(this.intervalId)
    this.intervalId = null
    this.setBreadcrumbDetails(null)
    // Cancelar las request pendientes
    if (this.requestCountGeoData > 0) {
      cancelGeoData('cancel geo-data requests')
    }
    if (this.requestCountPOI > 0) {
      cancelPOI('cancel poi requests')
    }
    // limpiar el timeout
    clearTimeout(this.$_debounceTimeoutId)
    this.$_debounceTimeoutId = null
  },
  async mounted () {
    const meta = this.$route.meta
    this.selectedTab = meta.tab
    // Inicializo los datos del selector
    await this.initializeSelector()
    // Cargar reporte diferido
    const reportKey = this.$route.query.key
    this.getData(reportKey)
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('reportDefer', {
      showReportDefer: 'showReportDefer',
      closeReportDefer: 'closeReportDefer',
      commitVisible: 'commitVisible'
    }),
    ...mapMutations({
      'setBreadcrumbDetails': 'breadcrumb/commitDetails'
    }),
    ...mapActions('breadcrumb', {
      setDetails: 'setDetails'
    }),
    /**
     * Incializador del selector
     */
    async initializeSelector () {
      const response = await thingApi.getValidHarvestFrontsNested()
      this.selectorModel.filters[0].data.cleanAndUpdate(response.data)
    },
    /**
     * Evento lanzado por el selector cuando se cierra con la X
     * @param {*} event
     */
    close () {
      this.showSelector = false
    },
    getData (reportKey) {
      if (reportKey) {
        this.currentReportKey = reportKey
        this.selectorDisabled = false
        this.showSelector = false
        this.disableRefreshButton = true
        this.commitVisible({ visible: true })
        reportStorageApi.getReport(reportKey).then(async response => {
          const { data, filters } = response.data
          const processedData = await harvestFrontLocationReportService.processReportData(data)
          this.tableHeaders.cleanAndUpdate(processedData.headers)
          this.setTableFilterRules(processedData.headers)
          this.setSortFunctions()
          this.tableData.cleanAndUpdate(processedData.data)
          this.mapConfiguration.cleanAndUpdate([processedData.map])
          processedData.things.sort((a, b) => this.sortFunction(a, b))
          this.markers.cleanAndUpdate(processedData.things)
          this.initializeSelectorData(filters)
          this.commitVisible({ visible: false })
          this.disableRefreshButton = false
          this.resetGeoData()
        })
      } else {
        this.selectorDisabled = false
        this.showSelector = true
      }
    },
    resetGeoData () {
      if (this.requestCountGeoData > 0) {
        cancelGeoData('cancel geo-data requests')
      }
      if (this.requestCountPOI > 0) {
        cancelPOI('cancel poi requests')
      }
      this.geoDataIndex = -1
      this.poiDataIndex = -1
      this.maxRadius = 20
      this.requestCountGeoData = 0
      this.requestCountPOI = 0
      // limpiar el timeout
      clearTimeout(this.$_debounceTimeoutId)
      this.$_debounceTimeoutId = null
      // si ya se han resuelto las referencias, vuelvo a ejecutar el proceso
      if (this.referenceResolved) {
        this.firePOIRequests()
      }
      // si ya se han resuelto las ubicaciones, vuelvo a ejecutar el proceso
      if (this.ubicationResolved) {
        this.fireGeoDataRequests()
      }
    },
    getReport (eventData) {
      this.things = []
      this.thingsId = []
      const thingSelected = eventData.filters[0].selectedData
      getChildrenFromList(thingSelected, this.things)
      this.thingsId = this.things.map(t => t.id)
      const that = this
      if (eventData.autoReport) {
        that.disableRefreshButton = false
        that.setReportDetails()
        that.getDataNotDeferred()
        clearInterval(that.intervalId)
        that.intervalId = setInterval(
          function () {
            that.getDataNotDeferred(true)
          },
          eventData.autoReportMinutes * 60 * 1000)
      } else {
        harvestFrontLocationReportService.getReportDeferred(that.thingsId, that.things, null, null, null, null, this.$route.path)
        this.showReportDefer({ updateFunction: this.getData.bind(this) })
      }
      this.showSelector = false
    },
    /**
     * TO DO: inicializar los datos de fecha y hora
     * @param {*} filterData
     */
    initializeSelectorData (filterData) {
      this.things = filterData.things
      const dataForSelector = this.getThingsSelected(filterData.things, this.selectorModel.filters[0].data)
      this.selectorModel.filters[0].selectedData.cleanAndUpdate(dataForSelector)
      this.thingsId = filterData.thingIds
      this.setReportDetails()
    },
    setReportDetails () {
      const things = this.selectorModel.filters[0].selectedData
      this.setDetails({
        detailData: {
          things },
        dataStructure: [
          { detailDataProp: 'things', propTitle: this.$t('things'), propType: 'LIST', propRefs: { list: things, value: 'id', text: 'name' } }
        ]
      })
    },
    /**
     * Método para obtener los equipos que tienen los pañoles seleccionados
     * @param {*} selectedData
     * @param {*} things
     */
    getThingsSelected (selectedData, things) {
      const harvestFrontIds = selectedData.map(d => d.harvestFrontId)
      const storeroomsIds = selectedData.map(d => d.id)
      const filteredThings = things.filter(t => harvestFrontIds.includes(t.id))
      return filteredThings.map(t => {
        const newChildren = t.children.filter(c => storeroomsIds.includes(c.id))
        return { ...t,
          children: newChildren }
      })
    },
    getDataNotDeferred (refresh = false) {
      this.commitVisible({ visible: true })
      harvestFrontLocationApi.getReportNotDeferred(this.thingsId)
        .then(async response => {
          const { data } = response
          const processedData = await harvestFrontLocationReportService.processReportData(data)
          if (!refresh) {
            this.tableHeaders.cleanAndUpdate(processedData.headers)
            this.setTableFilterRules(processedData.headers)
            this.setSortFunctions()
          }
          this.tableData.cleanAndUpdate(processedData.data)
          this.mapConfiguration.cleanAndUpdate([processedData.map])
          processedData.things.sort((a, b) => this.sortFunction(a, b))
          this.markers.cleanAndUpdate(processedData.things)
          this.commitVisible({ visible: false })
          this.resetGeoData()
        })
    },
    setTableFilterRules (headers) {
      this.tableFilterRules = {}
      headers.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    refreshTable () {
      this.getDataNotDeferred(true)
    },
    sortFunction (a, b) {
      const nameA = a.thingName.toLowerCase()
      const nameB = b.thingName.toLowerCase()
      if (nameA < nameB) {
        return -1
      } else if (nameA > nameB) {
        return 1
      } else {
        return 0
      }
    },
    selector () {
      this.showSelector = !this.showSelector
    },
    showSelectorButton () {
      return true
    },
    disableSelectorButton () {
      return this.selectorDisabled
    },
    validateSelector () {
      const that = this
      Vue.nextTick(function () {
        const harvestFrontSelected = that.selectorModel.filters[0].selectedData
        that.selectorModel.disableGetReportButton = harvestFrontSelected.length === 0
      })
    },
    clickRow () {
    },
    centerMap (selectedItem) {
      this.$refs.MapReportComponent.centerMap(selectedItem.position)
    },
    tabSelected (id) {
      this.selectedTab = id
    },
    setSortFunctions () {
      /*
      Para ordenar las columnas fecha y hora
      TO DO: analizar la posibilidad de incluir este tipo de sorting en el componente genérico
      */
      this.tableHeaders.find(header => header.value === 'positionTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
      this.tableHeaders.find(header => header.value === 'comunicationTimestamp').sort = (a, b) => { return dateSortFunction(a, b, 'DD/MM/YYYY HH:mm:ss') }
    },
    fireGeoDataRequests () {
      const header = this.tableHeaders.find(h => h.value === 'ubication')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getGeoData()
      this.ubicationResolved = true
    },
    firePOIRequests () {
      const header = this.tableHeaders.find(h => h.value === 'reference')
      if (header) {
        header.spinner = true
        header.cellConfig = { spinnerColor: '#03A9F4' }
        header.selected = true
      }
      this.getPOI()
      this.referenceResolved = true
    },
    getGeoData () {
      this.geoDataIndex++
      if (this.geoDataIndex < this.tableData.length) {
        if (!this.tableData[this.geoDataIndex].ubication) {
          this.geoRequest(this.tableData[this.geoDataIndex], this.geoDataIndex)
        } else {
          this.getGeoData()
        }
      }
    },
    getPOI () {
      this.poiDataIndex++
      if (this.poiDataIndex < this.tableData.length) {
        if (!this.tableData[this.poiDataIndex].reference || this.tableData[this.poiDataIndex].reference === '-') {
          this.poiRequest(this.tableData[this.poiDataIndex], this.poiDataIndex)
        } else {
          this.getPOI()
        }
      }
    },
    geoRequest (data, index) {
      this.requestCountGeoData++
      if (this.requestCountGeoData < 10) {
        this.getGeoData()
      }
      geoReferenceApi.getGeoData(data.lat, data.long, this.maxRadius).then(response => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
        const updatedItem = this.tableData[index]
        updatedItem.ubication = response.data.geoLocationData.title + ' - ' + response.data.geoLocationData.subtitle
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountGeoData--
        if (this.requestCountGeoData < 10) {
          this.getGeoData()
        }
      })
    },
    poiRequest (data, index) {
      this.requestCountPOI++
      if (this.requestCountPOI < 10) {
        this.getPOI()
      }
      geoReferenceApi.getPOI(data.lat, data.long, data.thingId, data.enterpriseId, data.activityId).then(response => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
        const updatedItem = this.tableData[index]
        updatedItem.reference = response.data ? response.data.name : undefined
        this.tableData.splice(index, 1, updatedItem)
      }).catch(() => {
        this.requestCountPOI--
        if (this.requestCountPOI < 10) {
          this.getPOI()
        }
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.addPOILat = parseFloat(data.lat)
          this.addPOILong = parseFloat(data.long)
          this.addPOIEnterpriseId = data.enterpriseId
          this.showAddPOI = true
          break
      }
    },
    closeAddPOIDialog () {
      this.showAddPOI = false
      this.poiDataIndex = -1
      this.getPOI()
    }
  },
  watch: {
    /*
      [EVALUAR SI CORRESPONDE LA APLICACIÓN DE ESTA VERIFICACIÓN]
      Si hay 10 requests activas, activo un temporizador. Si se cumple el tiempo, entonces cancelo
      las requests activas y continuo buscando.
      Este "reseteo" puede volver
    */
    requestCountGeoData () {
      const that = this
      if (this.requestCountGeoData === 10 && that.geoDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else if (that.tableData.filter(a => !a.ubication).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelGeoData('cancel geo-data requests')
          that.geoDataIndex = -1
          that.requestCountGeoData = 0
          that.getGeoData()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    },
    requestCountPOI () {
      const that = this
      if (this.requestCountPOI === 10 && that.poiDataIndex < that.tableData.length) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else if (that.tableData.filter(a => !a.reference).length > 0) {
        clearTimeout(that.$_debounceTimeoutId)
        that.$_debounceTimeoutId = null
        that.$_debounceTimeoutId = setTimeout(() => {
          cancelPOI('cancel poi requests')
          that.poiDataIndex = -1
          that.requestCountPOI = 0
          that.getPOI()
        }, 60000)
      } else {
        clearTimeout(this.$_debounceTimeoutId)
        this.$_debounceTimeoutId = null
      }
    },
    'selectorModel.filters': {
      handler: function () {
        this.validateSelector()
      },
      deep: true
    }
  }
}
