import { FilterType, DataType, CellType, SerieType } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

const headers = (lang, headersOptions) => {
  return [
    // 0
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.ENTERPRISE_SECTOR', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'enterpriseOrGroupName',
      width: 200
    },
    // 1
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.WORK_FRONT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'workFrontName',
      width: 200
    },
    // 2
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.ACTIVITY', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'activityName',
      width: 200
    },
    // 3
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.LAST_EVENT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.iconText,
      cellConfig: {
        icon: 'eventIcon',
        color: 'eventColor'
      },
      width: 150,
      value: 'eventName'
    },
    // 4
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.LAST_STATE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.textColor,
      cellConfig: {
        color: 'stateColor'
      },
      value: 'stateName',
      width: 200
    },
    // 5
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.ENGINE_STATE', { lang }),
      align: 'left',
      filterType: FilterType.multiSelect,
      selected: true,
      sortable: true,
      options: headersOptions.engineState,
      type: DataType.boolean,
      cellType: CellType.textBackgroundColor,
      cellConfig: {
        round: true,
        color: 'engineStateColor'
      },
      value: 'engineState',
      width: 200
    },
    // 6
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.MOVEMENT_STOPPED', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'movement',
      width: 200
    },
    // 7
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.POSITION_TIMESTAMP', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.textBackgroundColor,
      cellConfig: {
        round: false,
        outlined: true,
        color: 'positionTimestampColor'
      },
      value: 'positionTimestamp',
      width: 220
    },
    // 8
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.COMUNICATION_TIMESTAMP', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      cellType: CellType.textBackgroundColor,
      cellConfig: {
        round: false,
        outlined: true,
        color: 'comunicationTimestampColor'
      },
      value: 'comunicationTimestamp',
      width: 220
    },
    // 9
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.DRIVE_UNIT', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'driveUnits',
      width: 300
    },
    // 10
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.UBICATION', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'ubication',
      // spinner: true,
      width: 200
      // cellConfig: {
      //     spinnerColor: '#03A9F4',
      // },
    },
    // 11
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.REFERENCE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: false,
      sortable: true,
      type: DataType.string,
      value: 'reference',
      // spinner: true,
      // cellConfig: {
      //     spinnerColor: '#03A9F4',
      // },
      width: 200
    },
    // 12
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.ENVIROMENT_TEMPERATURE', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: true,
      sortable: true,
      type: DataType.string,
      value: 'environmentTemperature',
      width: 170
    },
    // 13
    {
      text: i18n.t('harvestFrontLocationReport.HEADERS.RAIN', { lang }),
      align: 'left',
      filterType: FilterType.textField,
      selected: false,
      sortable: true,
      type: DataType.string,
      value: 'rain',
      width: 150
    }
  ]
}

const Map = (lang, points, references) => {
  return {
    id: 0,
    name: '',
    groups: [
      {
        name: 'harvestFrontLocation',
        title: 'harvestFrontLocation',
        measure: undefined,
        referenceByInterval: false,
        series: [
          {
            type: SerieType.MARKERS,
            points
          }
        ],
        references,
        exclusive: true,
        displayOnStart: true
      }
    ],
    filters: [],
    info: {}
  }
}

export default {
  headers,
  Map
}
