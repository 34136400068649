import harvestFrontLocationApi from '@/api/harvestFrontLocation.api'
import { getTimeOffset } from '@/tools/functions'
import i18n from '@/i18n'
import conversionUtil from '@/utils/conversion.util'
import reportsUtil from '@/utils/reports.util'
import { ValueText, ReportConversionUtil, ThingTypes } from '@colven/common-domain-lib/lib'
import HarvestFrontLocationConstants from '@/constants/harvestFrontLocation.constants'

async function getReportDeferred (thingIds, things, sinceDate, sinceTime, toDate, toTime, route) {
  const filters = {
    thingIds,
    things,
    sinceDate,
    sinceTime,
    toDate,
    toTime,
    route
  }
  try {
    await harvestFrontLocationApi.getReportDeferred(thingIds, things, sinceDate, sinceTime, toDate, toTime, route, filters)
  } catch (error) {
    console.error(error)
  }
  return null
}

const processReportData = async (reportData) => {
  const timeOffset = getTimeOffset()
  const lang = localStorage.getItem('locale')
  const report = {}
  const { data, headerOptions } = await processData(reportData.data, lang, timeOffset)
  report.headers = HarvestFrontLocationConstants.headers(lang, headerOptions)
  report.data = data
  report.map = getMapReport(data, lang)
  report.things = reportData.things
  return report
}

/**
 * A partir del array de tramas, crea un array de datos para la tabla del reporte
 * TO DO: revisar el cálculo de los datos GRUPO, ACTIVIDAD, TEMPERATURA AMBIENTE, LLUVIA
 * @param data
 */
const processData = async (data, lang, timeOffset) => {
  const headerOptions = {
    engineState: [
      i18n.t('harvestFrontLocationReport.ENGINE_STATE.ON', { lang }),
      i18n.t('harvestFrontLocationReport.ENGINE_STATE.OFF', { lang })
    ]
  }

  let positionTimestampConversion
  let positionTimestamp
  let positionTimestampColor
  let comunicationTimestampConversion
  let comunicationTimestamp
  let comunicationTimestampColor
  data.forEach(d => {
    // Conversiones y acondicionamiento de datos
    positionTimestampConversion = ReportConversionUtil.applyTimezone(d.trackTimestamp, timeOffset)
    positionTimestamp = positionTimestampConversion.dateString + ' ' + positionTimestampConversion.timeString
    positionTimestampColor = reportsUtil.getTimestampColor(d.trackTimestamp)
    comunicationTimestampConversion = ReportConversionUtil.applyTimezone(d.timestamp, timeOffset)
    comunicationTimestamp = comunicationTimestampConversion.dateString + ' ' + comunicationTimestampConversion.timeString
    comunicationTimestampColor = reportsUtil.getTimestampColor(d.timestamp)
    // Estado del motor (traducción)
    d.engineState = {
      text: d.TELE_engine_running === true
        ? i18n.t('mapReport.ENGINE_STATE.ON', { lang })
        : (d.TELE_engine_running === false ? i18n.t('mapReport.ENGINE_STATE.OFF', { lang }) : undefined),
      value: d.TELE_engine_running
    }
    delete (d.TELE_engine_running)
    // Movimiento/detenido
    d.movement = new ValueText(d.TELE_speed_KPH,
      conversionUtil.speedDetentionToStringTranslated(d.TELE_speed_KPH, d.TELE_direction,
        d.TELE_stopped, d.TELE_stoppedTime, d.trackTimestamp, lang))
    delete (d.TELE_speed_KPH)
    delete (d.TELE_direction)
    delete (d.TELE_stopped)
    delete (d.TELE_stoppedTime)
    // Fecha y hora de la última comunicación
    d.comunicationTimestamp = comunicationTimestamp
    d.comunicationTimestampColor = comunicationTimestampColor
    delete (d.timestamp)
    // Fecha y hora de la última posición
    d.positionTimestamp = positionTimestamp
    d.positionTimestampColor = positionTimestampColor
    delete (d.trackTimestamp)
    // Temperatura del ambiente
    d.environmentTemperature = conversionUtil.temperatureToStringTranslated(lang, d.TELE_temperature_sensor)
    delete (d.TELE_temperature_sensor)
    // Lluvia
    d.rain = d.rain === true
      ? i18n.t('harvestFrontLocationReport.YES', { lang })
      : (d.rain === false ? i18n.t('harvestFrontLocationReport.NO', { lang }) : undefined)

    // Ubicación y referencia (se setean en null para que se active el spinner de carga)
    d.ubication = null
    d.reference = null

    // LAS MÁQUINAS QUE NO ESTÁN EN UNA UM NO SE MUESTRAN
    // Para reemplazar la constante WITHOUT_DRIVE_UNIT por su traducción
    /*
    d.driveUnits = d.driveUnits.replace(
        /WITHOUT_DRIVE_UNIT/g,
        i18n.t('harvestFrontLocationReport.WITHOUT_DRIVE_UNIT', { lang }))
    */

    // evento y estado
    if (d.eventName != null) {
      if (d.eventName[lang] != null) {
        d.eventName = d.eventName[lang]
      } else if (d.eventName.en != null) {
        d.eventName = d.eventName.en
      }
    }
    if (d.stateName != null) {
      if (d.stateName[lang] != null) {
        d.stateName = d.stateName[lang]
      } else if (d.stateName.en != null) {
        d.stateName = d.stateName.en
      }
    }
  })

  return { data, headerOptions }
}

/**
 * TO DO: revisar cuando esté hecho el microservicio del reporte
 * (esto es una copia del reporte de estado actual de unidades)
 * @param lang
 */
const getMapReport = (data, lang) => {
  const { points, references } = processMapReportData(data, lang)
  return HarvestFrontLocationConstants.Map(lang, points, references)
}

/**
 * TO DO: revisar cuando esté hecho el microservicio del reporte
 * (esto es una copia del reporte de estado actual de unidades)
 * @param lang
 */
const processMapReportData = (data) => {
  const points = []

  const references = Object.values(ThingTypes).map(t => ({ name: t.name, color: t.color, isInterval: false }))

  data.forEach(d => {
    const position = [parseFloat(d.lat), parseFloat(d.long)]
    let icon = ''
    switch (d.typeKey) {
      case 'HARVESTER': icon = 'harvester-icon.svg'; break
      case 'TRUCK': icon = 'truck-icon.svg'; break
      case 'TRACTOR': icon = 'tractor-icon.svg'; break
      case 'STOREROOM': icon = 'storeroom-icon.svg'; break
      case 'CANE_EQUIPMENT': icon = 'storeroom-icon.svg'; break
    }
    points.push({
      lat: position[0],
      lng: position[1],
      value: d.typeKey,
      filters: [],
      marker: {
        title: `<h4>${d.workFrontName}</h4>
        <hr>
        <h4>${d.thingName}</h4>
        <hr>
        ${d.stateName ? d.stateName + '<br>' : ''}
        ${d.movement.text}
        <br>
        ${d.driveUnitName ? d.driveUnitName : ''}
        `,
        tooltipDirection: 'right',
        tooltipPermanent: true,
        tooltipBorderColor: d.stateColor ? d.stateColor : undefined,
        type: 'img',
        ref: icon,
        size: 40
      }
    })
  })

  return { points, references }
}

export default {
  getReportDeferred,
  processReportData
}
