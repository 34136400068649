import service from '@/middleware'

const harvestFrontLocationApi = {
  async getReportDeferred (thingIds, things, sinceDate, sinceTime, toDate, toTime, route, filters) {
    const body = {
      reportQueryFilters: {
        thingIds,
        things,
        sinceDate,
        sinceTime,
        toDate,
        toTime,
        route
      },
      filters
    }
    const result = await service.http.post('/api/harvest-front-location/report-deferred', body)
    return result
  },
  async getReportNotDeferred (ids) {
    const result = await service.http.post('/api/harvest-front-location/report-not-deferred', { thingIds: ids })
    return result
  }
}

export default harvestFrontLocationApi
